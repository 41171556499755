import { Box, Flex, Typography, Image } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { IS_NA, IS_US, IS_JEWLR } from "helpers/application"
import { enableKlarnaCheckout } from "helpers/checkout/checkout_options"

const MainMethodImg = styled(Image).attrs((props) => ({
  height: "20px",
  ratio: "30 / 21",
  width: "auto",
  ...props,
}))``

const AltMethodImg = styled(Image).attrs((props) => ({
  height: "14px",
  width: "auto",
  ...props,
}))``

const AcceptPayments = ({ canProcessApplePay, canProcessGooglePay }) => (
  <Box mb="20px" mt={2} py="10px">
    <Flex alignItems="center" gap="5px" justifyContent="center" mb="10px">
      <Typography fontSize="12px" fontWeight="500" lineHeight="21px">
        Accepts:
      </Typography>
      <MainMethodImg
        alt="Visa"
        src={require("images/cart/sticky_methods_visa.svg")}
      />
      <MainMethodImg
        alt="Mastercard"
        src={require("images/cart/sticky_methods_master.svg")}
      />
      {IS_NA() && (
        <MainMethodImg
          alt="American Express"
          src={require("images/cart/sticky_methods_amex.svg")}
        />
      )}
      {IS_US() && (
        <MainMethodImg
          alt="Discover"
          src={require("images/cart/sticky_methods_discover.svg")}
        />
      )}
    </Flex>
    {IS_JEWLR() && (
      <Flex alignItems="center" gap="10px" justifyContent="center">
        {enableKlarnaCheckout() && (
          <AltMethodImg
            alt="Klarna"
            ratio="45 / 25"
            src={`${sl.config.jewlr_asset_url}images/cart/payment_methods/klarna_icon.png`}
          />
        )}
        {sl.config.enable_paypal && (
          <AltMethodImg
            alt="PayPal"
            ratio="58 / 16"
            src={require("images/cart/sticky_methods_paypal.svg")}
          />
        )}
        {canProcessApplePay && (
          <AltMethodImg
            alt="Apply Pay"
            ratio="500 / 209"
            src={require("images/cart/sticky_methods_apple.png")}
          />
        )}
        {canProcessGooglePay && (
          <AltMethodImg
            alt="Google Pay"
            ratio="41 / 16"
            src={require("images/cart/google-pay-checkout.svg")}
          />
        )}
      </Flex>
    )}
  </Box>
)

const mapStateToProps = (state) => {
  return {
    canProcessApplePay: state.cartUI.canProcessApplePay,
    canProcessGooglePay: state.cartUI.canProcessGooglePay,
  }
}

AcceptPayments.propTypes = {
  canProcessApplePay: PropTypes.bool,
  canProcessGooglePay: PropTypes.bool,
}

export default connect(mapStateToProps)(AcceptPayments)
